interface IConfig {
  // tslint:disable-next-line:no-any
  [key: string]: any;
}
const config = {
  default: {
    SENTRY_DSN: 'https://aa954f6abb3546e5a5e9269b67d8c3d1@sentry.cloudmvd.com/6',
    SENTRY_ENVIRONMENT: process.env.REACT_APP_STAGE,
    wx_chat_public: process.env.wx_chat_public,
    LOCAL_CACHE_VERSION: '0.5.8',
  },
  dev: {
    REACT_APP_DOMAIN: 'https://hulk-wukong.cloudmvd.com',
    wx_chat_public: 'http://storage-test.cloudmvd.com/hulk/wx_qrcode/hulk-wukong.png',
    SENTRY_DSN: '',
  },
  wukong: {
    REACT_APP_DOMAIN: 'https://hulk-wukong.cloudmvd.com',
    wx_chat_public: 'http://storage-test.cloudmvd.com/hulk/wx_qrcode/hulk-wukong.png',
  },
  bajie: {
    REACT_APP_DOMAIN: 'https://hulk-bajie.cloudmvd.com',
    wx_chat_public: 'http://storage-test.cloudmvd.com/hulk/wx_qrcode/hulk-bajie.jpg',
  },
  staging: {
    REACT_APP_DOMAIN: 'https://hulk-staging.cloudmvd.com',
    wx_chat_public: 'http://storage-test.cloudmvd.com/hulk/wx_qrcode/hulk-prod.png',
  },
  prod: {
    REACT_APP_DOMAIN: 'https://hulk-prod.cloudmvd.com',
    wx_chat_public: 'http://storage-test.cloudmvd.com/hulk/wx_qrcode/hulk-prod.png',
  },
} as { [key: string]: IConfig };

const domainConfig = {
  dev: {
    REACT_APP_DOMAIN: 'https://www-hulk-wukong.cloudmvd.com',
  },
  wukong: {
    REACT_APP_DOMAIN: 'https://www-hulk-wukong.cloudmvd.com',
  },
  bajie: {
    REACT_APP_DOMAIN: 'https://www-hulk-bajie.cloudmvd.com',
  },
  staging: {
    REACT_APP_DOMAIN: 'https://www-hulk-staging.cloudmvd.com',
  },
  prod: {
    REACT_APP_DOMAIN: 'https://crf.cloudmvd.com',
  },
} as IConfig;

let domainUrl: IConfig = {};
let activeConfig: IConfig = {};
if (process.env.REACT_APP_STAGE) {
  activeConfig = { ...config.default, ...config[process.env.REACT_APP_STAGE] };
  domainUrl = { ...domainConfig[process.env.REACT_APP_STAGE] };
}

export default {
  domainUrl,
  activeConfig,
};
