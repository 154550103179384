import React from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import FormAloneDate from '../FormAloneDate/FormAloneDate';
import FormCheckbox from '../FormCheckbox/FormCheckbox';
import FormCount from '../FormCount/FormCount';
import FormDate from '../FormDate/FormDate';
import FormDateTime from '../FormDateTime/FormDateTime';
import FormGroup from '../FormGroup/FormGroup';
import FormImage from '../FormImage/FormImage';
import FormInput from '../FormInput/FormInput';
import FormNumInput from '../FormNumInput/FormNumInput';
import FormRadio from '../FormRadio/FormRadio';
import FormSectionsDate from '../FormSectionsDate/FormSectionsDate';
// import FormSelect from '../FormSelect/FormSelect';
import FormSelectTag from '../FormSelectTag/FormSelectTag';
import FormSlider from '../FormSlider/FormSlider';
import FormTextear from '../FormTextear/FormTextear';
import FormTime from '../FormTime/FormTime';
import FormUploadImg from '../FormUploadImg/FormUploadImg';

/**
 * 根据的枚举渲染不同的表单
 * 接收参数相信请看字段接口类型
 */
export default (props: ISurveyHook) => {
  /**
   * 渲染表单
   * @param { ISubjectV } // 题目信息
   */
  const formRender = () => {
    const data = props.formObj;
    const isUpdate = data.subjectData.errMsg || '' + data.subjectData.updateKey;
    if (data.subjectData.showStatus) {
      switch (data.subjectData.subjectType) {
        case 'FormInput':
          return <FormInput formObj={data} key={isUpdate} />;
        case 'FormTextear':
          return <FormTextear formObj={data} key={isUpdate} />;
        case 'FormSlider':
          return <FormSlider formObj={data} key={isUpdate} />;
        case 'FormRadio':
          return <FormRadio formObj={data} key={isUpdate} />;
        case 'FormCheckbox':
          return <FormCheckbox formObj={data} key={isUpdate} />;
        case 'FormNumInput':
          return <FormNumInput formObj={data} key={isUpdate} />;
        case 'FormSectionsDate':
          return <FormSectionsDate formObj={data} key={isUpdate} />;
        case 'FormDate':
          return <FormDate formObj={data} key={isUpdate} />;
        case 'FormTime':
          return <FormTime formObj={data} key={isUpdate} />;
        /*case 'FormSelect':
          return <FormSelect formObj={data} key={isUpdate} />;*/
        case 'FormSelect':
          return <FormSelectTag formObj={data} key={isUpdate} />;
        case 'FormCount':
          return <FormCount formObj={data} />;
        case 'FormImage':
          return <FormImage formObj={data} />;
        case 'FormDateTime':
          return <FormDateTime formObj={data} key={isUpdate} />;
        case 'FormAloneDate':
          return <FormAloneDate formObj={data} key={isUpdate} />;
        case 'FormGroup':
          return <FormGroup formObj={data} key={isUpdate} />;
        case 'FormUploadImg':
          return <FormUploadImg formObj={data} />;
        default:
          return null;
      }
    }
    return null;
  };

  return formRender();
};
